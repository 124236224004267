@include sp-screen {}

.page-visiting-care {
  .kv {
    background: url(../img/visiting_care/key_visual.png) no-repeat center center;
    background-size: cover;
  }
  .content {
    counter-reset: number;
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(30);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(20);
      }
    }
  }
  .wrap {
    @include pc-screen {
      width: 800px;
      margin: 0 auto;
    }
    @include sp-screen {
      width: auto;
      margin: 0 5%;
    }
  }
  .block {
    margin-bottom: 120px;
    @include sp-screen {
      margin-bottom: 60px;
    }
    &__ttl {
      margin-bottom: 30px;
      @include rem-font-size(30);
      letter-spacing: 0.12em;
      text-align: center;
      @include sp-screen {
        margin-bottom: 15px;
        @include rem-font-size(20);
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 260px;
        width: 280px;
        @include sp-screen {
          padding: 0 54px;
          width: 180px;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: inline-block;
          width: 260px;
          height: 1px;
          background-color: $mainColor;
          @include sp-screen {
            width: 54px;
          }
        }
        &::before {
          left:0;
        }
        &:after {
          right: 0;
        }
      }
    }
  }
  .tel-guide{
    width: 740px;
    margin: 10px auto;
    text-align: center;
    @include rem-font-size(14);
    @include sp-screen {
      width: auto;
    }
  }
  .tel-info {
    width: 740px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 70px;
    border-radius: 7px;
    line-height: 1.4;
    background: rgba($mainColor, 0.1);
    @include rem-font-size(50);
    @include sp-screen {
      width: auto;
      padding: 20px 5%;
      @include rem-font-size(30);
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: solid 1px $mainColor;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
      .note {
        @include rem-font-size(17);
        @include sp-screen {
          @include rem-font-size(12);
        }
      }
    }
    .item--tel {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 56px;
        margin-right: 20px;
        background: url(../img/visiting_care/icon_tel.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 28px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
        span {
          display: block;
          font-size: 12px;
        }
      }
    }
    .item--fax {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 81px;
        margin-right: 30px;
        background: url(../img/visiting_care/icon_fax.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 40px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
      }
    }
  }
  .area {
    display: flex;
    @include sp-screen {
      flex-direction: column;
    }
    &__img {
      width: 100%;
      margin-right: 30px;
      @include sp-screen {
        width: 100%;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &__text-area {
      width: 100%;
      @include sp-screen {
        width: 100%;
      }
      .note {
        margin: 0 0 20px;
        @include rem-font-size(16);
        @include sp-screen {
          @include rem-font-size(12);
        }
      }
    }
    &__item {
      width: 100%;
      @include rem-font-size(22);
      line-height: 1.75;
      margin-bottom: calc(1em * 1.75);
      @include sp-screen {
        @include rem-font-size(16);
      }
    }
    &__head {
      color: $mainColor;
    }
    &__text {
      font-size: 18px;
    }
  }
  .point {
    &__item {
      display: flex;
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__img {
      width: 160px;
      margin-right: 40px;
      @include sp-screen {
        width: 80px;
        margin-right: 20px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &__text-area {
      width: calc(100% - 200px);
      margin-top: 10px;
      @include sp-screen {
        width: calc(100% - 100px);
        margin-top: 0;
      }
    }
    &__head {
      margin-bottom: 10px;
      @include rem-font-size(26);
      color: $mainColor;
      @include sp-screen {
        margin-bottom: 5px;
        @include rem-font-size(18);
      }
    }
    &__text {
      @include rem-font-size(18);
      @include sp-screen {
        @include rem-font-size(14);
      }
    }
  }
  .lead {
    &__text {
      @include rem-font-size(18);
      @include sp-screen {
        @include rem-font-size(14);
      }
      a {
        color: $mainColor;
        text-decoration: underline;
      }
    }
  }
  .list-area {
    width: 700px;
    margin: 0 auto 70px;
    @include sp-screen {
      width: 100%;
    }
    &__notes {
      font-size: 12px;
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__ttl {
      width: 185px;
      margin: 0 auto 30px;
      background: $mainColor;
      color: $white;
      text-align: center;
      @include rem-font-size(20);
      border-radius: 6px;
      line-height: 2.2;
      @include sp-screen {
        @include rem-font-size(18);
      }
    }
    .list {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      border: solid 1px $mainColor;
      li {
        box-sizing: border-box;
        width: 50%;
        padding: 10px;
        @include rem-font-size(18);
        border-bottom: solid 1px $mainColor;
        @include sp-screen {
          @include rem-font-size(14);
        }
        &:nth-child(odd) {
          border-right: solid 1px $mainColor;
        }
        &:nth-child(4n-1),
        &:nth-child(4n) {
          background: rgba($mainColor, 0.1);
        }
        &:last-child,
        &:nth-last-child(2) {
          border-bottom: none;
        }
      }
    }
    .note {
      @include rem-font-size(14);
      @include sp-screen {
        @include rem-font-size(12);
      }
    }
  }
  .flow {
    width: 745px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include sp-screen {
      width: 100%;
      flex-direction: column;
    }
    &__item {
      position: relative;
      width: 180px;
      @include sp-screen {
        padding-bottom: 40px;
        margin-bottom: 20px;
        width: 100%;
      }
      &:last-child {
        &::after {
          display: none;
        }
        @include sp-screen {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
      &::after {
        position: absolute;
        right: -78px;
        top: 40px;
        content: "";
        display: block;
        width: 50px;
        height: 44px;
        background: url(../img/visiting_care/icon_arrow.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          right: 0;
          left: 0;
          top: inherit;
          bottom: 0;
          margin: auto;
          width: 25px;
          height: 22px;
          transform: rotate(90deg);
        }
      }
    }
    &__ttl {
      margin-bottom: 10px;
      text-align: center;
      @include rem-font-size(20);
      color: $mainColor;
      @include sp-screen {
        @include rem-font-size(18);
      }
      .note {
        @include rem-font-size(16);
        @include sp-screen {
          @include rem-font-size(14);
        }
      }

    }
    &__img {
      display: block;
      width: 98px;
      margin: 0 auto 10px;
    }
    &__text {
      @include rem-font-size(15);
      @include sp-screen {
        @include rem-font-size(14);
      }
    }
  }
}
