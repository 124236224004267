@include sp-screen {}

.page-recruit {
  .kv {
    background: url(../img/recruit/key_visual.png) no-repeat center center;
    background-size: cover;
  }
  .content {
    counter-reset: number;
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(30);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(20);
      }
    }
  }
  .wrap {
    @include pc-screen {
      width: 1024px;
      margin: 0 auto;
      text-align: center;
    }
    @include sp-screen {
      width: auto;
      margin: 0 5%;
    }
  }
  
  .block {
    margin-bottom: 50px;
    @include sp-screen {
      margin-bottom: 60px;
    }
    &__ttl {
      margin-bottom: 30px;
      @include rem-font-size(30);
      letter-spacing: 0.12em;
      text-align: center;
      @include sp-screen {
        margin-bottom: 15px;
        @include rem-font-size(20);
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 260px;
        width: 280px;
        @include sp-screen {
          padding: 0 54px;
          width: 180px;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: inline-block;
          width: 260px;
          height: 1px;
          background-color: $mainColor;
          @include sp-screen {
            width: 54px;
          }
        }
        &::before {
          left:0;
        }
        &:after {
          right: 0;
        }
      }
    }
  }
  .tel-info {
    width: 740px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 70px;
    border-radius: 7px;
    line-height: 1.4;
    background: rgba($mainColor, 0.1);
    @include rem-font-size(50);
    @include sp-screen {
      width: auto;
      padding: 20px 5%;
      @include rem-font-size(30);
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: solid 1px $mainColor;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
      .note {
        @include rem-font-size(17);
        @include sp-screen {
          @include rem-font-size(12);
        }
      }
    }
    .item--tel {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 56px;
        margin-right: 30px;
        background: url(../img/visiting_care/icon_tel.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 28px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
        span {
          display: block;
          font-size: 12px;
        }
      }
    }
    .item--fax {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 81px;
        margin-right: 30px;
        background: url(../img/visiting_care/icon_fax.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 40px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
      }
    }
  }
  .lead {
    &__text {
      @include rem-font-size(18);
      @include sp-screen {
        @include rem-font-size(14);
      }
      a {
        color: $mainColor;
        text-decoration: underline;
      }
    }
  }

  .recruit {
    margin-bottom: 120px;
    &__ttl {
      margin-bottom: 30px;
      @include rem-font-size(23);
      text-align: center;
      &__img {
        width: 60px;
        margin: 0 auto 10px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .recruit_position > p {
    margin-bottom: 30px;
    text-align: center;
    @include rem-font-size(14);
  }

  .recruit_position > ul > li {
    margin-bottom: 30px;
  }
  .recruit_position > ul > li > a {
    display: flex;
    justify-content: space-between;
    background-color: #eeeeee;
    padding: 10px 20px 10px 10px;
  }
  .recruit_position > ul > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
  .recruit_position > ul > li > a .recruit_position_list_img {
    width: 391px;
  }
  .recruit_position > ul > li > a .recruit_position_list_txt {
    width: 615px;
    padding: 10px 0;
  }
  .recruit_position > ul > li > a .recruit_position_list_txt h3 {
    margin-bottom: 15px;
    color: $mainColor;
    font-weight: 500;
    font-size: 20px;
  }
  .recruit_position > ul > li > a .recruit_position_list_txt p {
    color: #333;
    line-height: 1.5;
    font-size: 16px;
  }
  .recruit_position > ul > li > a .recruit_position_list_more {
    position: relative;
    margin-bottom: 15px;
    padding-right: 10px;
    color: $mainColor;
    text-align: right;
    font-size: 14px;
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2px;
      margin: auto;
      vertical-align: middle;
      content: "";
      width: 6px;
      height: 6px;
      border-top: 2px solid $mainColor;
      border-right: 2px solid $mainColor;
      transform: rotate(45deg);
      @include sp-screen {
        width: 5px;
        height: 5px;
      }
    }
  }
  .recruit_position > ul > li > a .recruit_position_list_tag li {
    display: inline-block;
    margin: 0 5px 5px 0;
    border-radius: 1em;
    background-color: $mainColor;
    padding: 0.5em 1em;
    color: #fff;
    line-height: 1;
    text-align: center;
    font-size: 16px;
  }

  @include sp-screen {
    .recruit_position {
      margin: 0 auto;
      width: 100%;
    }
    .recruit_position > p {
      margin-bottom: 10px;
    }
    .recruit_position > ul > li {
      margin-bottom: 15px;
    }
    .recruit_position > ul > li > a {
      display: block;
      padding: 10px;
    }
    .recruit_position > ul > li > a .recruit_position_list_img {
      display: none;
    }
    .recruit_position > ul > li > a .recruit_position_list_txt {
      width: 100%;
      padding: 0;
    }
    .recruit_position > ul > li > a .recruit_position_list_txt h3 {
      margin-bottom: 10px;
      font-size: 14px;
    }
    .recruit_position > ul > li > a .recruit_position_list_txt p {
      font-size: 12px;
    }
    .recruit_position > ul > li > a .recruit_position_list_more {
      margin-bottom: 10px;
      font-size: 10px;
    }
    .recruit_position > ul > li > a .recruit_position_list_tag li {
      font-size: 11px;
    }

  }
}
